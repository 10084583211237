export function data() {
  return {
    table: {
      rows: [],
      loading: true,
      columns: [
        {
          field: 'id',
          label: 'Id',
          sortable: true,
          searchable: true,
          width: '7%'
        },
        {
          field: 'type',
          label: 'Type',
          aria: 'Lot number',
          width: '25%',
          sortable: true,
          searchable: true
        },
        {
          field: 'stage',
          label: 'Stage',
          aria: 'Stage',
          width: '25%',
          sortable: true,
          searchable: true
        },
        {
          field: 'status',
          label: 'Status',
          aria: 'Status',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'date',
          date: true,
          label: 'Reported On',
          aria: 'Date',
          width: '25%',
          sortable: true,
          searchable: true
        },
        {
          field: 'actions',
          date: true,
          label: 'Actions',
          aria: 'Actions',
          width: '15%'
        }
      ]
    }
  }
}
