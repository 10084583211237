<template>
  <div>
    <SimpleButton
      :style="{ margin: '4px' }"
      :to="{
        path: `violationtabs/${detail}/violationDetails#tab`,
        params: {
          id: detail
        }
      }"
    >
      Details
    </SimpleButton>
  </div>
</template>

<script>
import SimpleButton from '@/components/buttons/Simple'

export default {
  components: {
    SimpleButton
  },
  props: {
    detail: Number,
    openEscalator: Function
  },
  methods: {}
}
</script>
