<template>
  <PageContent :title="$t('violations.title')" owner-violations>
    <ModernTable
      :columns="table.columns"
      :rows="table.rows"
      :filters="{
        show: true
      }"
      :loading="table.loading"
    />
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { computed } from './keys/computed'
import { watch } from './keys/watch'

export default {
  components: {
    ModernTable,
    PageContent
  },
  data,
  methods,
  computed,
  watch,
  mounted() {
    this.refresh()
  },
  i18n: {
    messages: {
      en: {
        violations: {
          title: 'Violations'
        }
      }
    }
  }
}
</script>
