/*
  import { listViolations } from '@/services/ViolationIssue/Violation/List';

  // for example:
    const { successful, message, list } = await listViolations ({
      hoaID,

      // optional
      ownerID
    });
    if (!successful) {
      notifyProblem (message);
      return;
    }

*/

import _get from 'lodash/get'
import kms from '@/services/kms'

export async function listViolations({ kms: kms_ = kms, hoaID, ownerID = null } = {}) {
  let message = ``

  if (!Number.isNaN(ownerID)) {
    let params = {
      hoaID
    }
    if (typeof ownerID === 'number') {
      params.ownerID = ownerID
    }

    try {
      const result = await kms_.get(`/ViolationIssue/Violation/ListBasic`, {
        params
      })

      let list = _get(result, 'results', [])
      if (!Array.isArray(list)) {
        console.debug(result)
      }

      return {
        successful: true,
        message: null,
        list
      }
    } catch (exception) {
      console.error(exception)
      message = 'The list of violations could not be retrieved'
    }
  }

  return {
    successful: false,
    message,
    list: []
  }
}
