import SimpleButton from '@/components/buttons/Simple'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import moment from 'moment'
import { listViolations } from '@/services/ViolationIssue/Violation/List'
import { notifyProblem } from '@/services/notify'
import Actions from '../components/Actions'

export const methods = {
  async parseRows({ list }) {
    const dateFormat = 'LL'

    const sortedList = _orderBy(list, 'createdDate', 'desc')

    this.table.rows = sortedList.map(entry => {
      let stage = ''
      stage = _get(entry, 'lastStepName', '')

      var isClosed = _get(entry, 'isClosed', false)

      var date = _get(entry, 'createdDate', '')
      try {
        date = moment(date).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      const id = _get(entry, 'violationID', '')

      return {
        id,
        type: _get(entry, 'violationTypeName', null),
        stage,
        status: isClosed === true ? 'Closed' : 'Open',
        date: {
          date,
          format: dateFormat
        },
        actions: {
          component: Actions,
          props: {
            detail: id,
            openEscalator: () => {
              this.$refs.escalator.open({
                id
              })
            }
          }
        },
        button: {
          component: SimpleButton,
          props: {
            to: {
              name: 'newconversation',
              params: {
                violationid: _get(entry, 'violationID', 0),
                violationownerid: _get(entry, 'ownerID', 0),
                violationlabel: `Add Homeowner - ${_get(entry, 'ownerName', '')}`
              }
            },
            text: 'Ask a Question'
          }
        }
      }
    })
  },

  async refresh() {
    this.table.loading = true

    // for example:
    const { successful, message, list } = await listViolations({
      hoaID: this.currentHoaId,
      ownerID: parseInt(this.currentOwnerId)
    })
    if (!successful && message !== undefined && message) {
      notifyProblem(message)
      return
    }

    await this.parseRows({ list })

    this.table.loading = false
  }
}
